<template>
    <v-container>
        <v-card>
            <v-card-title>
                <v-icon left color="primary">mdi-information-outline</v-icon>

                MercadoPago Events

                <v-spacer></v-spacer>

                <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details
                    class="ml-2"></v-text-field>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class="pa-0">
                <v-data-table :headers="headers" :items="events" :loading="loading" :search="search"
                    class="elevation-1">
                    <template v-slot:item.actions="{ item }">
                        <v-btn small color="primary" @click="getPreApprovalDetails(item)"
                            :loading="loadingDetails[item.id]">
                            Ver Detalles {{ item.id }} {{ item.data.id }}
                        </v-btn>
                    </template>

                    <template v-slot:item.data="{ item }">
                        <pre>{{ formatJSON(item.data) }}</pre>
                    </template>

                    <template v-slot:item.date="{ item }">
                        {{ formatDate(item.date) }}
                    </template>
                </v-data-table>
            </v-card-text>

            <!-- Dialog to show preapproval details -->
            <v-dialog v-model="showDetailsDialog" max-width="800px">
                <v-card>
                    <v-card-title>
                        Detalles de la Suscripción
                        <v-spacer></v-spacer>
                        <v-btn icon @click="showDetailsDialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <pre>{{ formatJSON(preapprovalDetails) }}</pre>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-card>
    </v-container>
</template>

<script>
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirestore, collection, query, orderBy, onSnapshot, doc, getDoc, getDocs } from 'firebase/firestore'
import moment from 'moment'

export default {
    name: 'MercadoPagoEvents',

    data() {
        return {
            search: '',
            events: [],
            loading: true,
            loadingDetails: {},
            showDetailsDialog: false,
            preapprovalDetails: null,
            headers: [
                { text: 'Tipo', value: 'type' },
                { text: 'Entidad', value: 'entity' },
                { text: 'Acción', value: 'action' },
                { text: 'Fecha', value: 'date' },
                { text: 'ID', value: 'id' },
                { text: 'Datos', value: 'data' },
                { text: 'Acciones', value: 'actions', sortable: false }
            ]
        }
    },

    mounted() {
        this.initializeEventsListener()
    },

    methods: {
        async initializeEventsListener() {
            const db = getFirestore()
            const eventsRef = collection(db, 'mercadoPagoEvents')

            try {
                const snapshot = await getDocs(eventsRef)
                this.events = snapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                        // Normalize date field
                        date: moment(doc.data().date || doc.data().date_created || doc.data().timestamp?.toDate() || new Date()).toDate()
                    }))
                    .sort((a, b) => b.date - a.date) // Sort by date in descending order

                this.loading = false
            } catch (error) {
                console.error('Error fetching events:', error)
                this.$toast.error('Error al cargar los eventos')
                this.loading = false
            }
        },

        async getPreApprovalDetails(item) {
            this.$set(this.loadingDetails, item.id, true)

            try {
                const functions = getFunctions()

                if (item.entity === 'preapproval') {
                    const getPreApproval = httpsCallable(functions, 'gym/getPreApproval')

                    const result = await getPreApproval({ preApprovalId: item.data.id })
                    this.preapprovalDetails = result.data
                } else if (item.type == 'payment') {
                    const getPaymentDetails = httpsCallable(functions, 'gym/getPaymentDetails')
                    const result = await getPaymentDetails({ paymentId: item.data.id })
                    this.preapprovalDetails = result.data
                } else if (item.entity == 'authorized_payment') {
                    debugger
                    const getSubscriptionDetails = httpsCallable(functions, 'gym/searchPlans')
                    const result = await getSubscriptionDetails({ email: 'item.data.payer.email' })
                    this.preapprovalDetails = result.data
                }

                this.showDetailsDialog = true
            } catch (error) {
                console.error('Error fetching preapproval details:', error)
                this.$toast.error('Error al obtener detalles de la suscripción')
            } finally {
                this.$set(this.loadingDetails, item.id, false)
            }
        },

        formatJSON(data) {
            return JSON.stringify(data, null, 2)
        },

        formatDate(date) {
            return moment(date).format('DD/MM/YYYY HH:mm:ss')
        }
    }
}
</script>

<style scoped>
.mercadopago-events {
    padding: 20px;
}

pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    max-height: 100px;
    overflow-y: auto;
    background: #1e1919;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
}

.v-dialog pre {
    max-height: 500px;
}
</style>