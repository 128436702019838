<template>
  <v-app id="app">

    <!-- style="background-color:rgb(15,12,36)"
 -->

    <Navbar @toggleDrawer="toggleDrawer" @toggleDrawerRight="toggleDrawerRight" :alerts="alerts"
      :drawerAlerts="drawerAlerts" @toggleDrawerRightAlerts="toggleDrawerRightAlerts" :drawer="drawer"
      :drawer2="drawer2" :noticationUpdatedAt="noticationUpdatedAt" v-if="isLogged && $vuetify.breakpoint.smAndDown"
      :unreadPosts="unreadPosts" style="z-index:10;">
    </Navbar>

    <!--     
 -->
    <v-navigation-drawer dark :fixed="!$vuetify.breakpoint.smAndDown" :permanent="!$vuetify.breakpoint.smAndDown"
      :expand-on-hover="!$vuetify.breakpoint.smAndDown" :app="$vuetify.breakpoint.smAndDown"
      :color="this.$vuetify.theme.dark ? '#1c1c1c' : ''" :temporary="$vuetify.breakpoint.smAndDown" :width="275"
      v-model="drawer" v-if="isLogged" class="elevation-0">


      <v-list class="py-0" v-bind:style="{ 'margin-top': $vuetify.breakpoint.smAndDown ? '60px' : 'unset' }">
        <v-list-item class="px-2" :to="'/perfil'">
          <v-list-item-avatar size="40">
            <UserImage small></UserImage>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ this.$store.state.Auth.token.claims.name }}
            </v-list-item-title>
            <!--             <v-list-item-subtitle>sandra_a88@gmail.com</v-list-item-subtitle>
 --> </v-list-item-content>
        </v-list-item>

      </v-list>



      <v-divider></v-divider>
      <v-list nav dense class="py-0">
        <!-- User profile section -->




        <!-- Navigation items -->
        <v-list-item-group class="mt-2">
          <template v-for="(item, i) in getItems">
            <!-- Regular menu item -->
            <v-list-item v-if="!item.items" :key="i" :to="item.route" @click="drawer = false" class="mb-1">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Group with subitems -->
            <v-list-group v-else :key="i" :prepend-icon="item.icon" no-action color="currentColor">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item v-for="(subItem, j) in item.items" :key="j" :to="subItem.route" @click="drawer = false"
                class="pl-8">
                <v-list-item-icon>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </template>







        </v-list-item-group>


      </v-list>
      <!-- Logout button -->
      <template v-slot:append>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cerrar sesion</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>



    <v-navigation-drawer right fixed temporary v-model="drawer2" width="350" v-if="isLogged"
      :app="$vuetify.breakpoint.smAndDown">
      <Feed class="mt-12" :drawer2="drawer2" @update-unread="updateUnreadPosts" />
    </v-navigation-drawer>


    <v-main id="main-container">
      <transition name="fade-transition" mode="out-in">
        <router-view key="router"></router-view>
      </transition>
    </v-main>

    <Messages
      v-if="isLogged && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')" />


    <RingBottomNavigation
      v-if="isLogged && $vuetify.breakpoint.smAndDown && ($store.state.Auth.token.claims.type == 'superuser' || $store.state.Auth.token.claims.type == 'admin')"
      id="bottomnav" v-bind:class="{ 'isIos': isIos && isStandalone }" :options="optionsAdmin" v-model="selected" />

    <RingBottomNavigation
      v-if="isLogged && $vuetify.breakpoint.smAndDown && ($store.state.Auth.token.claims.type != 'superuser' && $store.state.Auth.token.claims.type != 'admin')"
      id="bottomnav"
      v-bind:class="{ 'isIos': isIos && isStandalone, 'unreadMessage': unreadCount > 0 && $router.currentRoute.path != '/mensajes' }"
      :options="optionsClients" v-model="selected" />



    <v-bottom-navigation v-if="isLogged && $vuetify.breakpoint.smAndDown"
      v-bind:class="{ 'isIos': isIos && isStandalone }" color="primary" app grow class="elevation-4"
      style="height: 64px;">
      <v-btn x-large :to="'/entrenamiento'" class="px-2">
        <span>Entrenamiento</span>
        <v-icon>mdi-dumbbell</v-icon>
      </v-btn>

      <v-btn x-large :to="'/inicio'" class="px-2">
        <span>Inicio</span>
        <v-icon>mdi-view-dashboard</v-icon>
      </v-btn>

      <v-btn x-large :to="'/perfil'" class="px-2">
        <span>Cuenta</span>
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-bottom-navigation>



    <v-overlay v-model="overlay" persistent>
      <v-card class="mx-auto rounded-lg" max-width="400" elevation="8">
        <v-card-title class="primary white--text">
          <v-icon left color="white">mdi-information</v-icon>
          Actualización disponible
        </v-card-title>

        <v-card-text class="pa-4">
          <p class="text-h6">
            Se ha detectado una nueva actualización,
            para poder seguir usando la aplicación es necesario actualizarla.
          </p>
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="success" rounded x-large @click="confirmUpdate" :loading="loadingBTN">
            <v-icon left>mdi-refresh</v-icon>
            Actualizar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-overlay>
    <v-footer padless v-show="!isLogged">
      <v-card width="100%" @click="openUrl">
        <v-card-text class="text-center">
          <span class="caption">© {{ new Date().getFullYear() }} - Todos los derechos reservados</span>
          <v-avatar size="20" class="ml-4">
            <v-img class="mx-auto" src="android-chrome-192x192.png" alt="logo" style="display:inline-block" />
          </v-avatar>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
//import firebase from "./firebase";
import Navbar from "@/components/Navbar.vue";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import LoginManager from './models/login.js';
import { Workbox } from 'workbox-window';
import { Capacitor } from '@capacitor/core';
import {
  getFirestore, doc, onSnapshot, collection, query, where, orderBy, setDoc

} from 'firebase/firestore';
import EditNews from '@/components/EditNews.vue';
import UserImage from '@/components/profile/UserImage.vue';


import Messages from '@/views/Whatsapp.vue';

import { listenForUnreadCount } from '@/models/chat_listen.js';

import { setUserOnline, setUserOffline } from '@/models/user_status.js';


import { getMessaging, onMessage } from "firebase/messaging";

import Feed from '@/views/Feed.vue';


export default {
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }

    if (this.unsubscribeAlerts) {
      this.unsubscribeAlerts();
    }
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('focus', this.updateLastInteractionTime);
    window.removeEventListener('blur', this.calculateElapsedTime);
    //window.removeEventListener('focus', this.checkForWorkboxUpdates);

    const editorContent = document.querySelector('.vue-editor'); // Same selector as before
    if (editorContent) {
      const links = editorContent.querySelectorAll('a');
      links.forEach(link => {
        link.removeEventListener('click', this.handleLinkClick);
      });
    }
  },
  data() {
    return {
      selected: 1,
      optionsAdmin: [
        { id: 1, icon: 'mdi mdi-view-dashboard-outline', title: 'Inicio', path: '/inicio' },
        /*{ id: 4, icon: 'mdi mdi-bullhorn', title: 'Comunidad', path:'/comunidad' },*/
        { id: 2, icon: 'mdi mdi-clipboard-list-outline', title: 'Programas', path: '/programas' },
        { id: 3, icon: 'mdi mdi-account-group-outline', title: 'Usuarios', path: '/usuarios' },
        { id: 5, icon: 'mdi mdi-account-circle-outline', title: 'Cuenta', path: '/perfil' },
      ],
      optionsClients: [
        { id: 1, icon: 'mdi mdi-view-dashboard-outline', title: 'Inicio', path: '/inicio' },
        { id: 2, icon: 'mdi mdi-play-circle-outline', title: 'Programa', path: '/entrenamiento' },
/*         { id: 3, icon: 'mdi mdi-message-outline', title: 'Mensajes', 'path': '/mensajes' },
 */        { id: 4, icon: 'mdi mdi-account-circle-outline', title: 'Cuenta', path: '/perfil' },
      ],
      isCapacitor: Capacitor.isNativePlatform(),
      smAndDown: false,
      value: 0,
      drawer: false,
      drawer2: false,
      drawerAlerts: false,
      showPadding: true,
      items: [],
      overlay: false,
      isIos: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
      isAndroid: /android/i.test(navigator.userAgent),
      isChrome: navigator.userAgent.indexOf('Chrome') != -1,
      isStandalone: (window.navigator.standalone !== undefined) ? window.navigator.standalone : false,
      content: '',
      loadingNews: false,
      noticationUpdatedAt: null,
      unsuscribe: null,
      lastInteractionTime: new Date(),
      wb: new Workbox('/firebase-messaging-sw.js'),
      loadingBTN: false,
      unsubscribe: null,
      unsubscribeAlerts: null,
      alerts: [],
      unreadCount: 0,
      adminItems: [
        { title: 'Inicio', icon: 'mdi-view-dashboard-outline', route: '/inicio' },
        { title: 'Comunidad', icon: 'mdi-bullhorn-outline', route: '/comunidad' },

        { title: 'Usuarios', icon: 'mdi-account-group-outline', route: '/usuarios' },

        { title: 'Punto de Venta', icon: 'mdi-cash-register', route: '/punto-venta' },
        { title: 'Inventario & Tienda', icon: 'mdi-store-outline', route: '/inventario-tienda' },

        {
          title: 'Clases', icon: 'mdi-calendar-outline',
          items: [
            { title: 'Reservas', icon: 'mdi-calendar-clock', route: '/agenda/reservas' },
            { title: 'Configuración', icon: 'mdi-cog-outline', route: '/agenda/configuracion' },
            { title: 'Suscripciones', icon: 'mdi-account-cash', route: '/agenda/suscripciones' },
            { title: 'Suscripciones Mercado Pago', icon: 'mdi-account-cash', route: '/negocio/suscripciones-mercadopago' }
          ]
        },

        {
          title: 'Entrenamiento', icon: 'mdi-dumbbell',
          items: [
            { title: 'Programas', icon: 'mdi-clipboard-list-outline', route: '/programas' },
            { title: 'Glosario', icon: 'mdi-database-search-outline', route: '/glosario' },
            { title: 'Actividad', icon: 'mdi-history', route: '/entrenamiento/actividad' },

          ]
        },

        {
          title: 'Evaluaciones', icon: 'mdi-clipboard-text-outline',
          items: [
            { title: 'Calendario', icon: 'mdi-calendar-month', route: '/evaluaciones/calendario' },
            { title: 'Formularios', icon: 'mdi-file-document-edit-outline', route: '/evaluaciones/formularios' },
            /*             { title: 'Proformas', icon: 'mdi-human-male-height-variant', route: '/proformas' },
             */
          ]
        },
        {
          title: 'Negocio',
          icon: 'mdi-handshake-outline',
          items: [
            { title: 'Tareas', icon: 'mdi-order-bool-ascending-variant', route: '/tareas' },

            { title: 'Notificaciones E-mail', icon: 'mdi-email-outline', route: '/negocio/email' },

            { title: 'Análisis', icon: 'mdi-chart-line', route: '/negocio/analisis' },
            { title: 'Fuerza de Trabajo', icon: 'mdi-account-group-outline', route: '/negocio/workforce' }
          ]
        },
        {
          title: 'Finanzas',
          icon: 'mdi-cash-multiple',
          items: [
            { title: 'Ingresos y Gastos', icon: 'mdi-cash-sync', route: '/finanzas/ingresos-gastos' },
            { title: 'Pagos de Suscripción', icon: 'mdi-cash-check', route: '/finanzas/pagos-subscripcion' },
            { title: 'Resumen Financiero', icon: 'mdi-cash-multiple', route: '/finanzas/resumen-financiero' }
          ]
        },
        { title: 'Control de Acceso', icon: 'mdi-qrcode-scan', route: '/negocio/control-acceso' },
      ],

      userItems: [
        { title: 'Inicio', icon: 'mdi-view-dashboard', route: '/inicio' },

        { title: 'Entrenamiento', icon: 'mdi-play', route: '/entrenamiento' },

        /*         { title: 'Comunidad', icon: 'mdi-view-dashboard', route: '/comunidad' },
         */
        //      { title: 'Reservas', icon: 'mdi-calendar-clock', route: '/clases' },
        { title: 'Progreso', icon: 'mdi-finance', route: '/progreso' },
        //{ title: 'Mi Plan', icon: 'mdi-play-outline', route: '/plan' },
        { title: 'Cronómetro', icon: 'mdi-timer-outline', route: '/cronometro' },
        // { title: 'Encuesta de Satisfacción', icon: 'mdi-comment-question-outline', route: '/encuesta' },
      ],
      unreadPosts: 0,
    }
  },
  components: {
    Navbar, EditNews, UserImage, Messages, Feed
  },
  computed: {
    ...mapState({
      isLogged: (state) => state.Auth.isLogged,
      token: (state) => state.Auth.token,
      initials: (state) => {
        let arr = state.Auth.token.claims.displayName.split(" ");
        if (arr.length === 1) return arr[0];
        else return (arr[0][0] + arr[1][0]).toUpperCase();
      },
    }),
    getItems() {
      if (this.$store.state.Auth.token.claims.type == 'superuser' || this.$store.state.Auth.token.claims.type == 'admin') {
        return this.adminItems;
      } else {
        return this.userItems;
      }
    }
  },
  async created() {
    this.$i18n.locale = this.$store.state.lang;
    this.$vuetify.theme.dark = this.$store.state.isDark;
    //this.$vuetify.theme.themes.dark.primary = this.$store.state.primary;
    //this.$vuetify.theme.themes.light.primary = this.$store.state.primary;
    await this.subscribeGoogleAuth();

    this.smAndDown = this.$vuetify.breakpoint.smAndDown;




    if (this.smAndDown) {
      this.items = this.items.splice(1, this.items.length - 1)
    }

    // Then, inside your method or lifecycle hook:
    this.wb.addEventListener('controlling', () => {
      window.location.reload(true);
    });


    // To prompt the user to refresh the app when there's an update
    this.wb.addEventListener('waiting', () => {
      try {
        // this.overlay = true;
        //this.wb.messageSW({ type: 'SKIP_WAITING' });
        //window.location.reload(true);
      } catch (error) {
        console.log(error);

      }
    });

    this.wb.addEventListener('installed', event => {
      //console.log('New Service Worker installed.');
    });

    this.wb.addEventListener('activated', event => {
      // console.log('New Service Worker activated.');
    });

    this.wb.addEventListener('redundant', event => {
      // console.log('New Service Worker redundant.');
    });


    this.wb.addEventListener('externalinstalled', event => {
      // console.log('New Service Worker externalinstalled.');
    });

    this.wb.register();

    //this.subscribeToAlerts();
  },
  mounted() {

    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      // ...
    });

    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.isStandalone = true;
    }

    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    window.addEventListener('focus', this.updateLastInteractionTime);
    window.addEventListener('blur', this.calculateElapsedTime);
    //window.addEventListener('focus', this.checkForWorkboxUpdates);

    if (this.$store.state.Auth.token.claims.type != 'admin') {

      listenForUnreadCount(this.$store.state.Auth.token.claims.user_id, (count) => {
        this.unreadCount = count;

        //find the messages icon on the dom and invert between the two icons changing the classes, the badge when count is greater than 0
        let messagesIcon = document.querySelector('.mdi-message-outline');
        let messagesBadgeIcon = document.querySelector('.mdi-message-badge-outline');

        if (messagesIcon && count > 0) {
          messagesIcon.classList.add('mdi-message-badge-outline');
          messagesIcon.classList.remove('mdi-message-outline');
        }

        if (messagesBadgeIcon && count == 0) {
          messagesBadgeIcon.classList.remove('mdi-message-badge-outline');
          messagesBadgeIcon.classList.add('mdi-message-outline');
        }


      });

      setUserOnline(this.$store.state.Auth.token.claims.user_id, this.$store.state.Auth.token.claims.name);
    }

  },
  methods: {
    updateUnreadPosts(count) {
      this.unreadPosts = count;
    },
    openUrl() {
      window.open('https://www.instagram.com/tutreino.app/', '_blank');
    },
    getLabelForAlertType(type) {
      switch (type) {
        case 'checkin_no_reservation':
          return 'Asistencia sin reserva previa, por favor reserve antes de asistir.';
        case 'reservation_not_used':
          return 'Reserva no utilizada, por favor cancele si no va a asistir.';
        case 'user_disabled':
          return 'Usuario deshabilitado, por favor contacte al administrador.';
        case 'user_subscription_expired':
          return 'Uso con suscripcion expirada, por favor renueve su suscripcion.';
        case 'plan_completed':
          return 'Intento asistencia con plan completado, por favor adierase a su plan.';
        case 'user_license_paused':
          return 'Uso con licencia activa, debe desactivar la licencia para poder asistir.';
        case 'late_checkin':
          return 'Asistencia fuera de horario, por favor asista en el horario correspondiente.';
        default:
          return type;
      }
    },

    getAlertTypeForVAlert(type) {
      switch (type) {
        case 'checkin_no_reservation':
          return 'info';
        case 'reservation_not_used':
          return 'error';
        case 'user_disabled':
          return 'error';
        case 'user_subscription_expired':
          return 'error';
        case 'plan_completed':
          return 'warning';
        case 'user_license_paused':
          return 'warning';
        case 'late_checkin':
          return 'warning';
        default:
          return 'info';
      }
    },


    getColorForAlertType(type) {
      switch (type) {
        case 'checkin_no_reservation':
          return 'info';
        case 'reservation_not_used':
          return 'error';
        case 'user_disabled':
          return 'error';
        case 'user_subscription_expired':
          return 'error';
        case 'plan_completed':
          return 'warning';
        case 'user_license_paused':
          return 'warning';
        case 'late_checkin':
          return 'warning';
        default:
          return 'info';
      }
    },

    subscribeToAlerts() {
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          const db = getFirestore();
          const alertsRef = collection(db, `users/${user.uid}/alerts`);

          // Assuming you want to fetch alerts from the last 2 weeks
          const twoWeeksAgo = new Date();
          twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);

          const q = query(alertsRef, where("date", ">=", twoWeeksAgo), orderBy("date", "desc"));

          this.unsubscribeAlerts = onSnapshot(q, (querySnapshot) => {
            this.alerts = [];
            querySnapshot.forEach((doc) => {
              this.alerts.push({ id: doc.id, ...doc.data() });
            });
          });
        } else {
          if (this.unsubscribeAlerts) {
            this.unsubscribeAlerts();
          }
        }
      });
    },
    confirmUpdate() {
      this.loadingBTN = true
      this.wb.messageSW({ type: 'SKIP_WAITING' });
    },
    async checkForWorkboxUpdates() {
      if (this.wb) {
        await this.wb.update();
      }
    },
    addLinkClickListener() {
      const editorContent = document.querySelector('.vue-editor'); // Same selector as before


      if (editorContent) {
        const links = editorContent.querySelectorAll('a');

        // Remove existing event listeners
        links.forEach(link => {
          link.removeEventListener('click', this.handleLinkClick);
        });

        // Add new event listeners
        links.forEach(link => {
          link.addEventListener('click', this.handleLinkClick);
        });
      }
    },
    handleLinkClick(event) {
      event.preventDefault();
      const href = event.currentTarget.getAttribute('href');
      this.$router.push(href);
    },
    updateLastInteractionTime() {
      this.lastInteractionTime = new Date();
    },

    calculateElapsedTime() {
      const currentTime = new Date();
      const elapsed = currentTime - this.lastInteractionTime; // Time in milliseconds

      // Check if the elapsed time is greater than 30 minutes
      if (elapsed > (300000) * 6 && this.$store.state.Auth.isLogged && this.$store.state.Auth.token.claims.type != 'superuser' && this.$store.state.Auth.token.claims.type != 'admin' && this.$store.state.Auth.token.claims.type != 'entrenador') {

        //  check if iphone 

        if (this.isIos) {
          window.location.reload(true);
        }
        // check if android
        if (this.isAndroid) {
          window.location.reload();
        }

      }
      // else if with the same conditions but if elapsed more than a day
      else if (elapsed > ((300000) * 12) * 24 && this.$store.state.Auth.isLogged && (this.$store.state.Auth.token.claims.type == 'superuser' || this.$store.state.Auth.token.claims.type == 'admin' || this.$store.state.Auth.token.claims.type == 'entrenador')) {
        window.location.reload(true);
      }
    },

    handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        this.calculateElapsedTime();
      }
      this.updateLastInteractionTime();
    },
    updateNews(content) {
      this.content = content;

      this.$nextTick(() => {
        this.addLinkClickListener();
      });
    },
    async getNews() {
      this.loadingNews = true;
      const db = getFirestore();
      const docRef = doc(db, `configurations/news`);

      this.unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (!docSnap.exists()) {
          const news = { content: '', updatedAt: new Date() };
          setDoc(docRef, news);
          this.content = '';
          this.noticationUpdatedAt = new Date();
        } else {
          let data = docSnap.data();
          this.content = data.content.replace(/target="_blank"/g, '');
          this.noticationUpdatedAt = new Date(data.updatedAt.seconds * 1000);
          if (this.$refs.editNews) {
            this.$refs.editNews.contentHtml = data.content;
          }
        }

        this.loadingNews = false;
        this.$nextTick(() => {
          this.addLinkClickListener();
        });
      }, (error) => {
        console.error("Error fetching news: ", error);
        this.loadingNews = false;
      });
    },

    toggleDrawer() {
      this.drawer = !this.drawer
    },
    toggleDrawerRight() {
      this.drawer2 = !this.drawer2;
      if (this.drawer2) {
        // Feed opened - clear badge and update last check time
        this.unreadPosts = 0;
        this.$store.dispatch("updateLastPostCheck", Date.now());
      }
    },
    toggleDrawerRightAlerts() {
      this.drawerAlerts = !this.drawerAlerts

      this.$store.dispatch("changeLastAlertUpdate", true);
    },
    logout() {
      this.$store.dispatch("Auth/userLogout");
      LoginManager.logout()
      this.drawer = false
      //check if route is not '/' and redirect to login
      if (this.$route.path != '/') {
        this.$router.push('/login')
      }
    },
    async subscribeGoogleAuth() {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
/*           this.getNews();
 */          this.$store.dispatch("Auth/userLogin", user);
          this.showPadding = false;
        } else {
          this.logout();
          this.showPadding = true;
        }
      });
    },
  },
  watch: {
    isLogged: {
      handler: function (val) {
        if (val) {
/*           this.getNews();
 */        } else {

          LoginManager.logout();

        }
      },
      immediate: true
    }
  },
}


</script>

<style lang="scss">
/* html{
  overflow-y: hidden !important;
} */
#app {
  overflow-y: hidden !important;
}

.v-application--wrap {
  overflow-y: hidden !important;
}

.safeMargin {
  padding-bottom: calc(40px + env(safe-area-inset-bottom));
}

/*   #main-container {
  /*   margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top); */
/* max-height: calc(100dvh - 54px) ;
    overflow: scroll; 
}; */

body {
  background-color: var(--v-primary-base);
  overflow-y: hidden !important;
}

#nav {
  transform: none !important;
}

#app.dontShow #nav {
  display: none !important;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 40px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.v-btn:focus {
  border: 0px;
  outline: 0px;
}

.theme--dark.v-bottom-navigation {
  background-color: #272727;
  color: #FFFFFF;
}

.isIos {
  padding-bottom: 20px;
}


//background color var primary base and transparency
.v-btn--active {
  background-color: rgba(var(--v-primary-base), 0.5) !important;

}

.glass {
  background: rgba(255, 255, 255, 0.04) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  //backdrop-filter: blur(5.8px) !important;
  //-webkit-backdrop-filter: blur(5.8px) !important;
  border: 1px solid rgba(255, 255, 255, 0.05) !important;
}




.rg-btn-container-foreground {
  --border-color: var(--v-primary-base) !important;

  --border-color: var(--v-primary-darken2) !important;
  --icon-color: var(--v-primary-base) !important;
  --background-color: #FFFFFF;
  --title-color: var(--v-primary-base) !important;
  --badge-color: var(--v-error-base) !important;
  box-shadow: 0px 0px !important;

  z-index: 6 !important;
}

.theme--dark .rg-btn-container-foreground {
  color: whitesmoke !important;
  background-color: #272727 !important;
}

.rg-btn-title {
  text-transform: capitalize !important;
}

.rg-btn-container-foreground.isIos {
  padding-bottom: 20px !important;
  height: 84px !important;
}

.unreadMessage .rg-btn-icon .mdi-message-outline {
  color: var(--v-error-base) !important;
}

.unreadMessage .rg-btn-icon .mdi-message-badge-outline {
  color: var(--v-error-base) !important;
}


@media (min-width: 1264px) {
  .v-main__wrap>.container {
    max-width: calc(100% - 55px) !important;
    margin-right: 0px !important;
    margin-left: 55px !important;
  }
}

@media (min-width: 960px) {
  .v-main__wrap>.container {
    max-width: calc(100% - 55px) !important;
    margin-right: 0px !important;
    margin-left: 55px !important;
  }
}



.v-application--is-ltr .v-data-table__mobile-row__cell {
  text-align: left !important;
}

.v-tab {
  text-transform: none;
  letter-spacing: normal;
}
</style>